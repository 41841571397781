import React, { Component }  from 'react';


class LineMoosic extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  }

  //https://www.youtube.com/watch?v=exucYUoiJLw
  componentDidMount() {
    //var path = document.querySelector('.path-komma-rum');
    //var length = path.getTotalLength();
    //console.log(length);
  }

  render() {
    return (
      <div className="line-komma-rum">
        <svg  version="1.1" id="Ebene_4" width="1994.256" height="1101.323"
          viewBox="0 0 1994.256 1101.323" overflow="visible" enableBackground="new 0 0 1994.256 1101.323" xmlSpace="preserve">
          <g>
            <path className="path-komma-rum" fill="none" stroke="#FFEC00" strokeWidth="1.5" d="M93.641,0c0,27.931-5.849,45.588-24.027,73.993
		c-20.137,31.465-116.585,98.82-39.343,194.6c48.568,60.225,252.749,21.56,516.055,21.56c99.08,0,155.509-41.256,273.19-119.712
		c46.625-31.084,87.792-45.8,217.102-45.612c189.091,0.277,273.671,2.458,303.07-56.34c42.739-85.48-225.36-102.965-268.1,66.054
		c-10.085,39.882,19.153,36.419,64.109,34.969s1086.675-37.035,759.617,62.168c-152.995,46.406-438.984,58.209-514.829,60.226
		c-134.766,3.583-291.987-28.839-310.841,34.969c-16.852,57.037-15.611,313.868,240.901,303.069
		c275.536-11.603,560.018-15.284,613.936,18.96c107.313,68.157,104.414,262.483-116.014,337.891
		c-60.908,13.052-101.856,22.276-264.83,13.313c-162.974-8.961-220.688-28.016-301.455-31.694
		c-80.766-3.679-140.487-4.529-142.763-2.83c-2.276,1.697-2.276,83.766-2.276,83.766h18.771c0,0-4.141,17.384-18.771,19.811
		c-1.705,0.283-0.567,31.413,0.57,31.413c16.78,0,70.688-30.937,73.904-112.594"/>/>
          </g>
        </svg>
      </div>
    );
  }
}

export default LineMoosic;
