import React, { Component }  from 'react';
import Line from './Line';
import imageMe from '../images/me.jpg';

import { smoothScroll } from '../helperFunctions/smoothScrolling';


class Header extends Component {

  componentDidMount() {
    smoothScroll('.app-header', 2000);
  }

  render() {
    return (
      <header className="app-header">
        <Line />
        <img className="image-me" src={imageMe} alt="myself" />
        <div className="app-header__text">
          <h3 className="upside-down float-right">KREATIVER KOPF</h3>
          <h3 className="float-right">WEBENTWICKLERIN</h3>
          <h3 className="float-right">ANGEHENDE YOGALEHRERIN</h3>
        </div>
      </header>
    );
  }
}

export default Header;
