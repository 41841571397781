import React, { Component }  from 'react';


class LineCV extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  }

  //https://www.youtube.com/watch?v=exucYUoiJLw
  componentDidMount() {
    //var path = document.querySelector('.path-cv');
    //var length = path.getTotalLength();
    //console.log("cv-path: " + length);
  }

  render() {
    return (
      <div className="line-cv">
        <svg  version="1.1" id="Ebene_2" width="768.997" height="2777.738"
          viewBox="0 0 768.997 2777.738" overflow="visible" enableBackground="new 0 0 768.997 2777.738" xmlSpace="preserve">
          <g>
            <defs>
              <rect id="XMLID_1_" width="768.997" height="2777.738"/>
            </defs>
            <path className="path-cv" clipPath="url(#XMLID_3_)" fill="none" stroke="#FFEB00" strokeWidth="1.5" d="M402.923,4.404
              c0,0,4.725,17.008,20.495,26.623c43.626,26.595,124.072,29.125,251.811,53.858c95.115,18.416,40.27,70.535-7.738,75.425
              c-5.683,0.579-54.803,1.889-144.567,5.197c-83.862,3.089-143.622,12.755-176.692,39.212c-17.413,13.931-28.166,78.348-7.38,90.874
              c20.788,12.527,33.071,0.062,20.788-8.473c-12.283-8.534-34.961,3.749-41.574,28.316c-6.614,24.566,27.221,76.149,46.118,70.228
              s30.236-33.66,10.394-29.291c-19.842,4.369-31.182,16.063-35.434,33.071c-4.252,17.007,8.213,82.415,28.056,83.101
              c19.842,0.687,23.622-25.77,5.669-19.156s-36.851,79.37-36.851,106.299c0,26.929,17.772,15.833,32.891,8.811
              s31.181-44.41,11.339-35.906c-4.085,1.751-61.418,94.067-12.284,105.406c49.134,11.339,22.335-79.622-2.061,32
              s-71.641,543.433,181.589,569.89c76.772,8.021,131.956-23.693,154.521-58.931c106.267-165.951-475.693-116.15-275.466,28.695
              c88.819,64.252,132.283,52.913,268.346,20.787c183.645-43.36-143.023-281.986-258.098-154.87
              c-115.145,127.194-106.49,425.523-107.571,433.086c-4.46,31.22,2.397,94.661-38.74,137.008
              c-32.126,33.071-188.976,55.748-252.284,29.292c-63.307-26.457-22.677-60.944,55.749-81.26
              c78.425-20.315,122.834,19.842,118.11,60.472c-4.725,40.631-99.213,74.646-116.221,103.465
              c-17.008,28.819-33.071,66.614-17.952,144.095c15.118,77.479,91.653,123.78,255.118,105.827
              c163.464-17.953,124.724-50.08,190.866-85.984c66.142-35.906,218.267-102.048,238.11-0.945
              c19.842,101.103-13.229,262.204-153.071,318.897c-139.842,56.692-164.882,65.308-180.473,96.851
              c-15.591,31.542-240.944,13.229-292.914,55.724c-43.447,35.526-142.542,107.539-77.952,196.56
              c77.952,107.44,112.862,142.753,101.55,188.032"/>
          </g>
        </svg>
      </div>
    );
  }
}

export default LineCV;
