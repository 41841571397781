import React, { Component }  from 'react';
import LineImpressum from './LineImpressum';

import { smoothScroll } from '../helperFunctions/smoothScrolling';

class Impressum extends Component {

  constructor(props) {
      super(props);
      this.state = {
        visible: false
       };
       this.toggleVisibility = this.toggleVisibility.bind(this);
       this.showContact = this.showContact.bind(this);
  }

  toggleVisibility() {
    this.setState({
      visible: !this.state.visible
    });
  }

  showContact() {
    smoothScroll('.contact', 2000);
  }

  render() {
    return (
      <div className="impressum">
        <span>&copy; 2020  &bull;  Sonja Nürenberg  &bull;  <span className="link" onClick={this.showContact} >Kontakt</span>  &bull;  <span className="link" onClick={this.toggleVisibility} >Impressum</span></span>
        <div className={this.state.visible ? "visible" : "hidden"}> 
          <LineImpressum className={this.state.visible ? "visible" : "hidden"}/>
          <div className="impressum-content"> 
            <span className="impressum-close" onClick={this.toggleVisibility}>&times;</span>
            <h2>Impressum</h2>
            <p>
                <br/>
                Sonja Nürenberg
                <br/><br/>
                Olgastraße 126, 70180 Stuttgart
                <br/><br/>
                Email: sonja.nuerenberg95[at]gmail.com
                <br/><br/>
                Inhaber und verantwortlicher Herausgeber i.S.d. § 55 RStV: Sonja Nürenberg
                <br/><br/>
                Urheberhinweis
                <br/><br/>
                Die auf dieser Website veröffentlichten Inhalte und Werke sind urheberrechtlich geschützt. Eine nicht durch das deutsche Urheberrecht gestattete Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder Urhebers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Speicherung, Verarbeitung oder Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Insbesondere eine Einbindung der Website oder Teile in sog. Frames im Rahmen fremder Internetangebote ist untersagt. Die reine, wertneutrale Verlinkung auf diese Website ist ebenso wie die Herstellung von Kopien und Downloads für den persönlichen, nichtkommerziellen Gebrauch ist gestattet.
                <br/><br/>
                Hinweise zum Datenschutz:
                <br/><br/>
                Durch den Besuch der Website werden – außer im Falle der Nutzung des Kontaktformulars oder der E-Mail-Adressen keine personenbezogenen sondern lediglich anonymisierte Daten über den Zugriff (Datum, Uhrzeit, Sessiondauer etc.) zu statistischen Zwecken erhoben. Eine Weitergabe an Dritte erfolgt nicht.
                <br/><br/>
                Der Anbieter weißt ausdrücklich darauf hin, dass eine Datenübertragung über das Internet (Kontaktformular, E-Mail, etc.) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff Dritter geschützt werden kann. Sämtliche Angaben zum Anbieter oder dessen Mitarbeitern, insbesondere E-Mail, Telefon oder Fax, dürfen ohne vorherige schriftliche Zustimmung oder bestehendem geschäftlichem Kontakt keinesfalls für gewerbliche Werbung genutzt werden. Sowohl Anbieter als auch alle anderen auf dieser Seite genannten natürlichen oder juristischen Personen widersprechen ausdrücklich jeglicher kommerziellen Nutzung oder der Weitergabe an Dritte von personenbezogener Daten.
                <br/><br/>
                Verlinkung auf externe Seiten:
                <br/><br/>
                Externe Links sind vor Verlinkung ausführlich und gewissenhaft auf erkennbare Rechtsverstöße geprüft worden. Der Anbieter weißt darauf hin, dass er keinerlei Einfluß auf aktuelle und zukünftige Inhalte verknüpfter externer Seiten hat. Insbesondere macht sich der Anbieter dieser Website gelinkte Inhalte ausdrücklich nicht zu eigen. Die Inhalte externer Seiten, die von dieser Seite aus verlinkt sind, unterliegen der Haftung des jeweiligen Anbieters.
                <br/><br/>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Impressum;
