import React, { Component }  from 'react';

class Contact extends Component {

  render() {
    return (
      <div className="contact">
       <h3>sonja.nuerenberg95@gmail.com
         <br/><br/>
         Olgastraße 126
         <br/>
         70180 Stuttgart
       </h3>
      </div>
    );
  }
}

export default Contact;
