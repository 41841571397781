import React, { Component }  from 'react';


class Line extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  }

  componentDidMount() {
    //var path = document.querySelector('.path');
    //var length = path.getTotalLength();
    //console.log(length);
  }

  render() {
    return (
      <div className="line">
        <svg  version="1.1" id="Ebene_1" width="135.83" height="771.613"
              viewBox="0 0 123.481 701.466" overflow="visible" enableBackground="new 0 0 123.481 701.466">
          <g>
            <defs>
              <rect id="XMLID_1_" width="135.83" height="771.613"/>
            </defs>
            <path className="path" clipPath="url(#XMLID_3_)" fill="none" stroke="#FFEB00" strokeWidth="1.5" d="M24.663,0.008
              c0.03,2.892,0.47,273.736,0,276.58c-0.679,4.107-4.914,14.423-5.564,15.782c-0.488,1.018,0.792,26.414,1.485,26.634
              c0.267,1.465,2.024,1.806,3.121,2.258c2.288,0.941,3.591,1.563,6.186,1.619c1.607,0.036,4.854,1,5.774,1.234
              c0.406,0.269,2.504,0.811,3.172,0.925c0.903,0.153,1.527,0.388,2.36,0.841c3.428,1.861,3.867,3.675,4.622,7.449
              c0.318,1.588,0.235,3.354,0.646,4.899c0.324,1.221,1.434,2.611,2.007,3.683c0.508,0.947,0.883,2.107,1.625,2.974
              c3.283,3.826,7.106,7.218,10.931,10.071c2.697,2.01,7.083,3.874,8.541,7.289c1.135,2.656,0.449,6.635,0.449,9.576
              c0,3.362-0.781,4.81,0.063,7.909c-2.106-1.28-3.081-2.331-5.658-2.788c0.097-1.688-1.886-2.203-2.047-4.068
              c-1.393-0.334-2.363-2.078-3.699-2.823c-1.446-0.807-3.525-1.519-5.09-1.927c-2.248-0.585-6.117-0.364-8.149,0.484
              c-1.114,0.466-1.649,1.032-2.219,1.929c-0.696,1.096-0.115,2.173-0.686,3.264c-0.074,0.144-0.985,0.526-1.214,0.813
              c-0.437,0.544-0.374,0.904-0.685,1.479c-0.245,0.453-0.623,0.989-0.853,1.387c-0.352,0.608,0.21,1.122-0.793,1.465
              c0.999,0.2,1.416-0.439,2.186-0.531c1.064-0.127,2.255,0.023,3.331,0.023c2.206,0,4.411-0.005,6.616,0
              c1.73,0.005,4.693-0.185,5.758-1.864c0.424-0.668,0.31-3.719,0-4.338c-0.515-1.028-1.975-1.24-2.925-1.354
              c-1.631-0.195-3.977-0.139-5.067,1.286c-0.714,0.935-0.96,5.354,0.255,6.188c0.357,0.246,2.439,0.393,2.922,0.45
              c1.089,0.131,2.189,0.104,3.301,0.105c2.261,0.001,4.806,0.304,7.023-0.07c-0.004-0.648,0.15-1.207,0.47-1.69
              c-0.265-0.339-2.263-3.168-2.382-3.72c-0.98-1.354-2.519-1.978-3.952-2.655c-2.417-1.142-3.953-1.778-6.909-1.785
              c-1.443-0.005-2.664-0.198-3.917,0.565c-0.754,0.46-2.464,1.502-3.002,2.244c-0.333,0.46-0.149,1.536-0.425,2.128
              c-0.265,0.57-0.758,0.748-1.074,1.215c-0.608,0.899-2.369,2.223-2.456,3.213c-0.674,0.17-1.409,0.174-2.087,0.012
              c-0.184-0.56-0.184-1.188,0-1.747c1.014-0.146,3.009-0.401,3.978,0.023c0.907,0.399,2.25,2.2,1.911,3.143
              c-1.633-0.165-3.005,0.709-4.753,0.471c-0.178-0.847-0.069-1.741-0.088-2.621c0,3.098,0.024,6.197,0,9.295
              c-0.015,1.96,0.702,3.304,1.136,5.12c0.375,1.567,0.264,3.308,1.035,4.721c0.255,0.068,0.512,0.126,0.772,0.174
              c0.437,2.248,0.546,4.676,0.553,7.005c0.01,3.045-0.953,4.271-3.478,6.028c-2.168,1.508-3.211,1.47-5.709,1.163
              c-3.016-0.369-5.927-1.17-5.376-4.756c0.951-0.017,1.618-1.221,2.552-1.604c1.095-0.451,2.305-0.493,3.459-0.265
              c-0.35,0.907-2.05,1.878-2.989,2.174c-0.976,0.308-2.228-0.269-3.131,0.052c-0.336,0.046-0.519,0.249-0.551,0.609
              c0.065-0.024-1.404,0.588-1.327,0.535c-1.754,1.215-2.886,2.054-5.094,2.203c-0.627,0.043-1.887,0.224-2.475,0.039
              c-0.768-0.241-0.751-1.144-1.795-1.174c-0.323-2.585,0.517-3.466,3.166-3.045c0.486,3.268-10.413,1.393-9.387,0.792
              c1.643,1.639,2.007,4.733,1.329,7.174c-1.199,4.313-6.287,6.113-9.68,8.051c1.396-0.061,3.252,0.343,4.586-0.015
              c0.591-0.159,1.161-0.775,1.517-0.873c0.746-0.205,1.508,0.08,2.258-0.059c0.733-0.135,1.265-0.708,1.977-0.874
              c0.964-0.224,3.79-3.444,4.748-3.595c1.454-0.228,7.445,4.57,8.929,4.323c2.479-0.414,12.52-6.281,11.925-1.834
              c4.712,0.646,9.692-0.125,14.396,0.609c0.73,1.886-1.5,1.816-2.603,2.281c-1.301,0.548-2.841,1.542-4.058,2.205
              c-0.711,0.388-1.589,0.481-2.268,0.802c-1.029,0.487-1.293,1.308-2.076,2.093c-0.712,0.713-2.031,1.254-2.809,2.025
              c-0.892,0.888-1.612,2.055-2.646,2.838c-5.088,3.858-13.438,3.155-19.381,2.14c-2.986-0.511-5.659-1.909-7.945-3.969
              c-1.369-1.233-3.885-3.179-4.524-5.014c4.112-0.717,7.399,2.189,11.448,2.585c5.399,0.527,12.51,0.387,17.541-1.689
              c1.406-0.58,2.635-1.456,3.773-2.208c2.171-1.435,4.576-1.558,7.033-1.901c1.288-0.181,2.374-0.79,3.857-0.838
              c1.682-0.055,3.375-0.002,5.059-0.002c-3.632,0-7.354-0.355-10.89,0.566c-2.206,0.575-4.465,0.998-6.649,1.65
              c-2.61,0.78-4.833,1.81-7.595,2.175c-4.395,0.581-9.399,1.451-13.828,0.614c-2.099-0.398-3.605-0.972-5.795-1.022
              c-1.295-0.029-2.518-0.057-3.327-1.219c0.185,0.774,0.856,0.803,1.169,1.284c0.785,1.204,1.379,2.772,1.957,4.117
              c0.988,2.301,2.668,4.928,3.087,7.414c0.196,1.16-0.286,1.996-0.506,3.045c-0.349,1.67-0.116,11.187,0.737,12.899
              c1.018,2.045,5.486,7.566,6.835,8.021c1.124,0.379,12.135,0.897,25.445-10.827c12.794-11.27,25.866-34.153,25.866-36.992
              c0-5.028,2.488-10.886,2.087-15.903c-0.282-3.523-2.11-7.306-1.979-10.715c-0.649,0.107-1.209-0.03-1.525-0.54
              c1.161-1.68,2.615,2.828,2.995,3.671c1.378,3.054,1.881,6.855,5.175,8.864c1.941,1.185,4.331,2.527,6.57,2.969
              c2.74,0.538,6.009,0.086,8.806,0.086c2.604,0,9.853,1.38,10.603-2.261c0.147-0.719-0.578-2.898-0.75-3.646
              c-0.439-1.918-1.537-3.373-3.318-4.197c-2.657-1.229-7.291-1.445-8.08,2.012c-0.462,2.022-0.12,4.479-0.102,6.54
              c0.028,3.356,1.815,5.112,4.418,7.132c5.172,4.014,13.127,3.374,19.119,2.386c-3.865-1.965-9.877-1.581-13.982-0.617
              c-4.637,1.088-9.67,4.025-11.373,8.694c-1.713,4.691-1.918,14.151,2.251,17.72c4.978,4.26,16.247,4.146,20.331-1.169
              c1.313-1.707,3.219-4.511,2.063-6.725c-1.013-1.938-4.08-2.363-5.994-2.533c-3.013-0.269-4.061,0.24-5.23,3.299
              c-1.553,4.057-1.711,9.029-1.689,13.337c0.014,2.893,1.598,4.123,4.162,5.561c3.958,2.218,10.516,3.333,14.553,0.837
              c3.027-1.87,3.807-4.068,4.516-7.565c1.559-7.688,1.244-16.242,0.745-24.027c-0.472-7.357-1.53-14.222-4.312-21.015
              c-2.548-6.218-2.724-13.042-4.76-19.409c-0.943-2.949-1.766-4.998-4.223-6.968c-2.563-2.058-6.758-5.025-10.023-5.647
              c-2.962-0.563-7.033,0.831-7.925,3.943c-1.169,4.076,1.987,7.753,5.457,9.613c1.718,0.921,5.505,1.225,7.353,0.449
              c3.459-1.45,3.659-6.612,3.709-9.77c0.09-5.817-4.276-11.265-8.095-15.247c-4.799-5.003-11.76-9.074-18.522-10.627
              c-0.741-0.17-13.217-3.24-11.364,1.733c2.983,0.385,5.522,0.161,7.483-2.371c1.918-2.478,3.436-6.314,4.69-9.177
              c0.946-2.157,1.032-4.952,0.749-7.316c-0.499-4.17-3.855-6.358-7.839-6.979c-2.813-0.438-5.505-0.653-6.11,2.376
              c-0.943,4.734,3.686,10.776,6.428,14.017c2.676,3.161,5.779,5.512,9.01,8.039c1.932,1.516,3.672,3.229,5.218,5.144
              c5.09,5.492,10.233,10.925,14.841,16.841c5.018,6.442,8.537,12.437,10.119,20.538c0.482,3.099-5.395,39.357-11.553,48.481
              c-1.828,2.71-4.819,4.975-5.822,8.145c-0.695,2.198-0.854,4.521-0.841,6.803c0.036,6.604-0.257,11.62,4.126,17.148
              c3.043,3.839,3.646,10.141,1.563,14.638c-0.773,1.166-1.291,2.436-1.556,3.807c-0.263,1.693,0.015,2.721-0.612,4.3
              c-1.887,4.755-4.941,9.246-7.297,13.8c-6.539,12.647-7.08,27.034-7.08,41.013c0,5.954-0.184,11.925,0.332,17.86
              c0.417,4.795,0.84,9.562,1.189,14.358c0.261,3.579,1.074,7.225,0.816,10.823c0.035-0.167,0.07-0.333,0.105-0.5
              c0.607,10.474,0.861,20.957,0.759,31.453c0.363,29.094,5.302,54.686,14.751,84.921"/>
          </g>
        </svg>
      </div>
    );
  }
}

export default Line;
