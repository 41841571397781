import React, { Component }  from 'react';
import LineThesis from './LineThesis';

class Thesis extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
    this.showThesisSummary = this.showThesisSummary.bind(this);
}

  showThesisSummary() {
    this.setState({
      show: true
    });
  }

  render() {
    return (
      <div className="thesis">
        <LineThesis />
        <div className="thesis-headline" onClick={this.showThesisSummary}>
          <h2>Bachelorarbeit</h2>
          <p>Persönlichkeitsbestimmung mit Hilfe<br /> von tiefen neuronalen Netzen</p>
        </div> 
        {this.state.show ?
          <div className="thesis-summary">
            <p>
              2018
              <br /><br />
              In dieser Arbeit soll ermittelt werden, ob der Persönlichkeitstyp einer Person nach Myers-Briggs
              anhand ihrer Posts im Internet mit Hilfe von tiefen neuronalen Netzen bestimmt werden kann.
              Hierfür stehen zwei verschiedene Datensätze mit insgesamt 10k Daten zur Verfügung. Die
              Texte der Datensätze werden zunächst bereinigt, verarbeitet und in Vektoren umgewandelt.
              Anschließend werden Modelle mit drei verschiedenen Convolutional Neural Network (CNN)
              Architekturen trainiert und ihre Güte ausgewertet. Außerdem wird analysiert, wie gut sich das
              Modell, das mit dem ersten Datensatz trainiert wurde, auf den zweiten anwenden lässt. In der
              Arbeit werden zudem Probleme von Natural Language Processing beleuchtet, sowie mögliche
              Lösungen erläutert. Die Klassifiation mittels CNN innerhalb der jeweiligen Datensätze erzielt
              F1-Score Werte zwischen 0,794 und 0,841. Diese Ergebnisse hängen von der getesten Dimension
              des Persönlichkeitsindikators ab.
              <br /><br />
              Technologien: Python, Keras, TensorFlow, Scikit-Learn
            </p>
          </div>
          : null
        } 
      </div>
    );
  }
}

export default Thesis;
