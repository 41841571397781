//https://www.youtube.com/watch?v=oUSvlrDTLi4

export function smoothScroll(targetClassName, duration){
    var target = document.querySelector(targetClassName);
    var targetHeight = target.clientHeight;
    var windowHeight = window.innerHeight;
    var targetPositionY = 0;
    targetHeight >= windowHeight
        ? targetPositionY = target.getBoundingClientRect().top - 40
        : targetPositionY = target.getBoundingClientRect().top - ((windowHeight - targetHeight) / 2);
    var startPositionY = window.pageYOffset; 
    var targetPositionX = target.getBoundingClientRect().left - 40;
    var startPositionX = window.pageXOffset;
    var startTime = null;

    function animation(currentTime){
        if(startTime === null) startTime = currentTime;
        var timeElapsed = currentTime - startTime;
        var runY = ease(timeElapsed, startPositionY, targetPositionY, duration);
        var runX = ease(timeElapsed, startPositionX, targetPositionX, duration);
        window.scrollTo(runX, runY);
        if(timeElapsed < duration) requestAnimationFrame(animation)
    }

    function ease(t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    requestAnimationFrame(animation);
}