import React, { Component }  from 'react';
import LineImRahmenWeb from './LineImRahmenWeb';
import video from './../videos/im-rahmen-web.mp4';

import { smoothScroll } from '../helperFunctions/smoothScrolling';


class ImRahmenWeb extends Component {

  componentDidMount() {
    smoothScroll('.im-rahmen-web', 2000);
  }

  render() {
    return (
      <div className="im-rahmen-web">
      <LineImRahmenWeb />      
        <h2>Im Rahmen - Webeditor</h2>
        <p> 
            2017-2018
            <br/><br/>
            Ergänzend zu der <span className="link" onClick={() => this.props.setActiveProject(2)}>iOS-App</span> wurde ein Webeditor zur Eingabe neuer Spieldaten entworfen. Der Editor wurde in React.js und SCSS entwickelt und bietet die Möglichkeit, die Daten der Kunstwerke, Spiele, sowie der Beacons zu verwalten.  
            <br/><br/>
            Technologien: React.js, SCSS, Firebase
            <br/><br/>
            Entwicklung: Sonja Nürenberg, Antonia Verdier
            <br/>
            Design: Nico Stehle
        </p>
        <video id="im-rahmen-app-video" loop autoPlay muted playsInline>
          <source src={video} type="video/mp4" />
          <source src={video} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
}

export default ImRahmenWeb;
