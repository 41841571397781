import React, { Component }  from 'react';
import LineImRahmenApp from './LineImRahmenApp';
import imageImRahmen1 from './../images/im-rahmen-app/im-rahmen1.png';
import imageImRahmen2 from './../images/im-rahmen-app/im-rahmen2.png';
import imageImRahmen3 from './../images/im-rahmen-app/im-rahmen3.png';
import imageImRahmen4 from './../images/im-rahmen-app/im-rahmen4.png';
import video from './../videos/im-rahmen-app.mp4';

import { smoothScroll } from '../helperFunctions/smoothScrolling';


class ImRahmenApp extends Component {

  componentDidMount() {
    smoothScroll('.im-rahmen-app', 2000);
  }

  render() {
    return (
      <div className="im-rahmen-app">
        <LineImRahmenApp />
        <h2>Im Rahmen - iOS-App</h2>
        <p> 
            2017
            <br/><br/>
            Eine native iOS-App in Kooperation mit der Staatsgalerie Stuttgart, die während eines <a href="https://www.hdm-stuttgart.de/vorlesung_detail?vorlid=5212599">interdisziplinären Projekts</a> der HdM entwickelt wurde. Mit Hilfe von Beacons erkennt die App, wo sich die Besucher:innen im Museum befinden und zeigt ihnen verfügbare Mini-Spiele für umliegende Kunstwerke an. Im Anschluss an die Spiele werden spannende Infos zu dem passenden Kunstwerk angezeigt. 
            <br/><br/>
            Technologien: Swift 3, Firebase
            <br/><br/>
            App-Entwicklung: Aurelia Bachmann, Sonja Nürenberg, Antonia Verdier
            <br/>
            Design: Nico Stehle
            <br/>
            Usablility: Wiebke Niemann
            <br/>
            Projektmanagement: Anouk Ruhman
            <br/>
            Content: Andreas Dennenmoser, Mara-Sophie Rossberg
            <br/>
            Marketing: Klara Pokorny
        </p>
        <div className="im-rahmen-app-images">
          <img id="image-im-rahmen-app1" src={imageImRahmen1} alt="Screenshot Project Im Rahmen - iOS-App" />
          <img id="image-im-rahmen-app2" src={imageImRahmen2} alt="Screenshot Project Im Rahmen - iOS-App" />
          <img id="image-im-rahmen-app3" src={imageImRahmen3} alt="Screenshot Project Im Rahmen - iOS-App" />
          <img id="image-im-rahmen-app4" src={imageImRahmen4} alt="Screenshot Project Im Rahmen - iOS-App" />
        </div>
        <video id="im-rahmen-app-video" loop autoPlay muted playsInline>
          <source src={video} type="video/mp4" />
          <source src={video} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
}

export default ImRahmenApp;
