import React, { Component }  from 'react';
import imageProject1 from './../images/moosic/moosic1.png';
import imageProject2 from './../images/im-rahmen-app.png';
import imageProject3 from './../images/im-rahmen-web-app.png';
import imageProject4 from './../images/komma-rum.png';
import Moosic from './Moosic';
import KommaRum from './KommaRum';
import ImRahmenApp from './ImRahmenApp';
import ImRahmenWeb from './ImRahmenWeb';

import { smoothScroll } from '../helperFunctions/smoothScrolling';
/**
 * ToDo:
 *  - smoothScrolling verbessern (zentrierung waagerecht?)
 *  - impressum
 */


class Projects extends Component {

  constructor(props) {
      super(props);
      this.state = {
        activeProjects: [],
        projects: ['.moosic','.im-rahmen-app','.im-rahmen-web','.komma-rum']
      };
      this.setActiveProject = this.setActiveProject.bind(this);
      this.moveToProject = this.moveToProject.bind(this);
  }

  setActiveProject(activeProject) {
    var tempActiveProjects = this.state.activeProjects
    if(!tempActiveProjects.includes(activeProject)){
      tempActiveProjects.push(activeProject)
      this.setState({
        activeProjects: tempActiveProjects
      })
    } else {
      this.moveToProject(activeProject)
    } 
  }

  moveToProject(project) {
    smoothScroll(this.state.projects[project - 1], 2000);
  }

  render() {
    return (
      <div className="projects">
        <h2>PROJEKTE</h2>

        <div className="projects__moosic-container" onClick={() => this.setActiveProject(1)}>
          <img className="projects__moosic" src={imageProject1} alt="Projekt Moosic" />
          <div className="overlay">
            <div className="overlay-text">MOOSIC<br/><span>MOBILE WEB APP</span></div>
          </div>
        </div>
        {this.state.activeProjects.includes(1) ? <Moosic /> : null}
        
        <div className="projects__im-rahmen-container" onClick={() => this.setActiveProject(2)}>
        <img className="projects__im-rahmen" src={imageProject2} alt="Projekt Im Rahmen mobile App" />
        <div className="overlay">
            <div className="overlay-text">IM RAHMEN<br/><span>iOS-APP</span></div>
          </div>
        </div>
        {this.state.activeProjects.includes(2) ? <ImRahmenApp /> : null}

        <div className="projects__im-rahmen-web-container" onClick={() => this.setActiveProject(3)}>
        <img className="projects__im-rahmen-web" src={imageProject3} alt="Projekt Im Rahmen Webapplikation" />
        <div className="overlay">
            <div className="overlay-text">IM RAHMEN<br/><span>WEB EDITOR</span></div>
          </div>
        </div>
        {this.state.activeProjects.includes(3) ? <ImRahmenWeb setActiveProject={this.setActiveProject}/> : null}

        <div className="projects__komma-rum-container" onClick={() => this.setActiveProject(4)}>
        <img className="projects__komma-rum" src={imageProject4} alt="Projekt KommaRum" />
        <div className="overlay">
            <div className="overlay-text">KOMMA RUM<br/><span>RESPONSIVE WEBSEITE</span></div>
          </div>
        </div>
        {this.state.activeProjects.includes(4) ? <KommaRum /> : null}
      </div>
    );
  }
}

export default Projects;
