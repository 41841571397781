import React, { Component }  from 'react';


class LineMoosic extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  }

  //https://www.youtube.com/watch?v=exucYUoiJLw
  componentDidMount() {
    //var path = document.querySelector('.path-moosic');
    //var length = path.getTotalLength();
    //console.log(length);
  }

  render() {
    return (
      <div className="line-moosic">
        <svg  version="1.1" id="Ebene_3"  width="1653.503" height="798.378"
          viewBox="0 0 1653.503 798.378" overflow="visible" enableBackground="new 0 0 1653.503 798.378" xmlSpace="preserve">
          <path className="path-moosic" fill="none" stroke="#FFEC00" strokeWidth="1.5" d="M0.405,674.262c29.291-40.446,28.228-163.07,209.797-236.021
            C436.138,347.464,462.361,99.338,561.207,56.977c98.848-42.363,219.884,2.016,197.695,36.31c-22.19,34.294-185.59,0-185.59,34.294
            c0,34.293,114.985,30.258,197.693,12.103c82.708-18.155,218.291-36.651,316.711-32.276c90.778,4.034,148.686,116.648,161.098,189.22
            c6.03,35.262,26.889,101.703-235.735,81.096c-334.141-26.22-435.733-22.191-465.992,30.259
            c-30.259,52.45-42.361,114.985-24.207,213.832c18.153,98.847,44.381,147.261,121.036,157.347s810.945,30.26,899.705,10.086
            c88.761-20.172,88.759-36.31,92.795-106.914c4.035-70.605,54.168-567.105-42.363-647.547
            C1545.64-5.56,1473.018-7.577,1353.998,14.613c-111.002,20.694-121.604,51.421-108.932,80.69
            c4.918,11.359,10.178,14.866,22.189,24.208"/>
        </svg>
      </div>
    );
  }
}

export default LineMoosic;
