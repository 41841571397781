import React, { Component }  from 'react';


class LineImRahmenWeb extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  }

  //https://www.youtube.com/watch?v=exucYUoiJLw
  componentDidMount() {
    //var path = document.querySelector('.path-im-rahmen-web');
    //var length = path.getTotalLength();
    //console.log("im-rahmen-web: " + length);
  }

  render() {
    return (
      <div className="line-im-rahmen-web">
        <svg  version="1.1" id="Ebene_6" width="1955.749" height="995.835"
          viewBox="0 0 1955.749 995.835" overflow="visible" enableBackground="new 0 0 1955.749 995.835" xmlSpace="preserve">
          <path className="path-im-rahmen-web" fill="none" stroke="#FFEC00" d="M1955.56,146.053c-25.519,10.439-289.988,17.399-368.864,23.198
            c-79.88,5.874-440.783,0-598.536-13.919c-104.024-9.178-622.776-29.873-686.574,10.725c-63.797,40.599,142.057,23.874,177.355,6.674
            c15.801-7.699,64.95-33.074-70.757-31.317c-122.055,1.581-223.327,7.004-247.686,113.721
            c-21.955,96.182-63.18,644.879,215.208,686.638s729.632,85.863,830.525,19.72c104.396-68.44,105.175-228.37,104.396-270.264
            c-6.96-374.665-109.034-648.408-518.499-651.888c-409.463-3.479-487.18,39.439-602.015,56.838
            C75.277,113.577-58.754,123.631,28.561,0.289"/>
        </svg>
      </div>
    );
  }
}

export default LineImRahmenWeb;
