import React, { Component }  from 'react';


class LineImRahmenApp extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  }

  //https://www.youtube.com/watch?v=exucYUoiJLw
  componentDidMount() {
    //var path = document.querySelector('.path-im-rahmen-app');
    //var length = path.getTotalLength();
    //console.log(length);
  }

  render() {
    return (
      <div className="line-im-rahmen-app">
        <svg  version="1.1" id="Ebene_5" width="2155.247" height="1614.109"
          viewBox="0 0 2155.247 1614.109" overflow="visible" enableBackground="new 0 0 2155.247 1614.109" xmlSpace="preserve">
          <path className="path-im-rahmen-app" fill="none" stroke="#FFEC00" d="M2119.618,1275.614c23.991-18.656,66.643-66.642-2.665-141.28
            c-69.307-74.638-215.92-141.279-530.467-341.206c-152.881-97.171-554.462-447.829-437.172-669.08
            c117.291-221.252,530.47-87.972,130.617-37.324C880.083,137.375,133.7,129.378,53.73,148.036
            c-79.97,18.66,87.966,39.985,167.936,26.657c79.97-13.327,1233.129-126.138,613.104,303.886
            c-165.271,114.625-645.092,0-722.396,45.316C35.07,569.212,51.064,873.102,93.715,905.087
            c49.741,37.302,878.109-22.245,908.992,15.994c55.979,69.31,58.645,229.246-23.991,495.815
            c-82.636,266.565-317.214,5.33-594.444,66.642c-277.229,61.312-293.223,151.941-167.937,125.285
            c125.287-26.654,152.158-165.074-34.653-122.619c-117.294,26.657-170.603,87.967-181.266,103.96"/>
        </svg>
      </div>
    );
  }
}

export default LineImRahmenApp;
