import React, { Component }  from 'react';
import LineKommaRum from './LineKommaRum';
import imageKommaRum1 from './../images/komma-rum/komma-rum-web.png';
import imageKommaRum2 from './../images/komma-rum/komma-rum-mobile.png';
import imageKommaRum3 from './../images/komma-rum/komma-rum-web-2.png';
import imageKommaRum4 from './../images/komma-rum/komma-rum-mobile-2.png';

import { smoothScroll } from '../helperFunctions/smoothScrolling';


class KommaRum extends Component {

  componentDidMount() {
    smoothScroll('.komma-rum', 2000);
  }

  render() {
    return (
      <div className="komma-rum">
        <LineKommaRum />
        <h2>KommaRum</h2>
        <p> 
            2020
            <br/><br/>
            Eine Webseite, die für ein Festival in Frankfurt designt und entwickelt wurde. Um die Usability für
            mobile Endgerät zu optimieren, wurde ein Responsive Webdesign verwendet.
            <br/><br/>
            Technologien: React.js
        </p>
        <div className="komma-rum-images">
          <img id="image-komma-rum1" src={imageKommaRum1} alt="Screenshot Project Komma Rum" />
          <img id="image-komma-rum2" src={imageKommaRum2} alt="Screenshot Project Komma Rum" />
          <img id="image-komma-rum3" src={imageKommaRum3} alt="Screenshot Project Komma Rum" />
          <img id="image-komma-rum4" src={imageKommaRum4} alt="Screenshot Project Komma Rum" />
        </div>
      </div>
    );
  }
}

export default KommaRum;
