import React, { Component }  from 'react';


class LineImpressum extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  }

  //https://www.youtube.com/watch?v=exucYUoiJLw
  componentDidMount() {
    //var path = document.querySelector('.path-cv');
    //var length = path.getTotalLength();
    //console.log(length);
  }

  render() {
    return (
      <div >
        <svg className="line-impressum" version="1.1" id="Ebene_8" width="954.269" height="565.302"
          viewBox="0 0 954.269 565.302" overflow="visible" enableBackground="new 0 0 954.269 565.302" xmlSpace="preserve">
          <path className="path-impressum" fill="none" stroke="#FFEC00" strokeWidth="1.5" d="M954.253,564.802c-38.76-1.175-62.251,2.349-99.837-4.698
            c-31.962-5.993-65.624-72.294-80.037-178.678c-2.535-18.714-4.475-38.669-5.703-59.754
            c-8.223-140.945-21.143-190.276-17.619-231.384c3.523-41.109,23.491-76.345,41.109-41.109C809.785,84.414,643,58.574,572.527,35.084
            c-33.105-11.035-65.95-18.96-105.478-22.313c-44.614-3.785-97.743-1.746-169.365,8.218C162.612,39.782-32.362,164.283,5.223,255.897
            c37.585,91.614,95.138,270.145,342.967,241.955c247.828-28.188,528.543-9.396,420.486-306.555
            C660.618-105.862,533.767,36.258,469.167,23.338c-64.601-12.92-109.232-10.571-109.232-10.571"/>
        </svg>

        <svg className="background-impressum" version="1.1" id="Ebene_9" width="792.961" height="500.336"
          viewBox="0 0 792.961 500.336" overflow="visible" enableBackground="new 0 0 792.961 500.336" xmlSpace="preserve">
          <path fill="#FFFFFF" d="M773.879,380.925c-2.535-18.714-4.475-38.669-5.703-59.754c-8.223-140.945-21.143-190.276-17.619-231.384
            c3.523-41.109,23.491-76.345,41.109-41.109c17.618,35.236-149.167,9.396-219.64-14.094c-33.105-11.035-65.95-18.96-105.478-22.313
            c-44.614-3.785-97.743-1.746-169.365,8.218C162.112,39.282-32.862,163.783,4.723,255.397
            c37.585,91.614,95.138,270.145,342.967,241.955c247.828-28.188,528.543-9.396,420.486-306.555
            C660.118-106.362,533.267,35.758,468.667,22.838c-64.601-12.92-109.232-10.571-109.232-10.571"/>
          </svg>
      </div>
    );
  }
}

export default LineImpressum;
