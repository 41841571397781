import React, { Component }  from 'react';


class LineThesis extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  }

  //https://www.youtube.com/watch?v=exucYUoiJLw
  componentDidMount() {
    //var path = document.querySelector('.path-thesis');
    //var length = path.getTotalLength();
    //console.log("thesis: " + length);
  }

  render() {
    return (
      <div className="line-thesis">
        
        <svg  version="1.1" id="Ebene_7" width="412.653" height="1118.604"
	 viewBox="0 0 412.653 1118.604" overflow="visible" enableBackground="new 0 0 412.653 1118.604" xmlSpace="preserve">
<g>
	<g>
		<g>
      
			<path className="path-thesis" fill="none" stroke="#FFEC00" strokeWidth="1.5" d="M86.688,0.645c-4.275,24.187-15.453,46.986-23.537,70.032c-7.808,22.258-15.656,47.54-10.092,71.227
				c6.364,27.093,32.141,40.009,57.191,45.863c23.342,5.456,47.74,6.099,71.601,5.581c31.226-0.677,62.547-2.974,93.616-6.123
				c24.644-2.498,49.303-5.812,73.466-11.345c15.795-3.617,32.073-8.145,46.134-16.43c7.951-4.685,15.815-11.464,17.345-21.044
				c1.629-10.201-5.289-19.79-11.784-26.845c-12.912-14.026-29.945-24.375-46.333-33.773
				c-23.055-13.221-47.823-23.365-73.309-30.822c-48.211-14.106-100.162-18.342-149.749-9.559
				c-40.692,7.207-82.062,24.7-108.011,58.085c-11.252,14.476-18.755,31.812-21.775,49.875c-2.947,17.628-1.061,35.679,2.572,53.05
				c4.17,19.942,10.725,39.34,17.012,58.688c7.687,23.655,15.144,47.374,20.833,71.603c6.766,28.814,10.788,58.215,11.767,87.8
				c1.07,32.365,0.655,64.836,0.954,97.219c0.613,66.259,1.599,132.521,1.761,198.783c0.034,13.856-0.332,27.665-0.732,41.518
				c-0.262,9.061-0.524,18.122-0.787,27.184c-0.235,8.115,7.278,12.354,14.793,13.301c-0.083-0.475-0.167-0.95-0.251-1.425
				c-1.375,0.837-2.497,1.725-3.685,2.828c-0.729,0.729-1.463,1.451-2.202,2.169c-3.01,2.944-11.034,0.581-6.13-4.383
				c-0.396-0.277-0.792-0.555-1.189-0.833c-1.302,3.622-1.648,6.89,0.677,10.125c1.125,1.566,3.089,2.438,4.775,3.221
				c4.087,1.898,8.327,8.134,3.059,12.079c0.367,0.334,0.733,0.668,1.1,1.003c1.834-3.423,7.805-9.921,4.534-14.187
				c-3.204-4.177-9.105-3.563-13.569-1.845c-4.545,1.748-4.187,7.549-2.494,11.142c3.238,6.872,8.829,1.562,13.608,1.089
				c-0.151-0.472-0.302-0.943-0.452-1.416c-2.181,0.939-5.052,2.979-4.427,5.877c0.238,1.104,2.904,5.943,0.001,6.012
				c-1.888,0.044-4.046-0.766-5.555-1.863c-0.1,0.451-0.2,0.902-0.3,1.353c3.82-0.328,7.725,1.222,10.345,3.999
				c1.954,2.071,1.347,5.089-1.533,4.268c-1.134-0.322-2.218-0.883-3.28-1.383c-2.495-1.176-6.106-2.251-8.046,0.519
				c-2.484,3.548,1.275,8.447,3.601,10.965c3.372,3.65,8.027,3.776,12.115,1.511c0.516-0.286,0.614-1.196-0.062-1.381
				c-3.672-1.008-7.158,0.12-10.708,1.218c-6.109,1.888-12.864,3.738-19.297,3.628c-3.904-0.066-8.571-1.397-9.645-5.799
				c-0.717-2.938,1.395-6.28,2.937-8.551c3.386-4.984,9.113-9.304,15.36-7.32c5.716,1.815,9.313,10.087,9.476,15.563
				c0.083,2.789-0.725,6.914,1.326,9.259c1.466,1.676,3.234,2.333,5.373,2.828c1.464,0.34,2.933,0.539,4.275,1.259
				c3.465,1.855-0.014,9.032-2.474,9.991c0.312,0.369,0.624,0.738,0.936,1.107c2.408-2.996,6.187-8.836,2.632-12.524
				c-3.089-3.205-7.163-2.458-10.463,0.124c-2.622,2.052-3.461,7.59-1.557,10.313c2.586,3.699,8.697,3.857,12.682,2.751
				c0.942-0.261,0.701-1.532-0.261-1.477c-6.607,0.379-12.076-0.38-13.653,7.583c-1.081,5.459,0.893,11.863,3.331,16.62
				c2.382,4.648,6.211,8.561,9.369,12.695c1.063,1.391,1.985,2.854,2.774,4.412c2.597,5.127-5.393,2.974-7.495,1.954
				c-3.472-1.685-6.266-3.043-10.191-2.682c-0.793,0.072-0.762,1.169-0.13,1.441c3.191,1.377,6.555,2.2,9.87,3.217
				c1.794,0.55,3.459,1.27,5.099,2.197c0.929,0.526,7.223,7.017,2.912,8.104c-4.673,1.179-9.311-3.073-14.24-2.139
				c-3.351,0.635-2.325,4.29-0.869,6.184c2.69,3.499,10.141,3.821,14.071,4.158c0.965,0.082,1.104-1.407,0.142-1.494
				c-3.338-0.299-14.013-2.385-14.313,3.697c-0.288,5.835,9.44,7.784,13.693,8.1c0.966,0.071,1.101-1.383,0.142-1.494
				c-3.091-0.357-8.894-2.61-11.8-0.749c-1.353,0.866-3.317,2.845-1.876,4.559c2.11,2.511,5.722,3.231,8.758,4.016
				c1.115,0.288,2.183,0.721,3.246,1.155c3.93,1.607,0.761,4.472-2.131,4.415c-2.891-0.056-5.759-0.672-8.63-0.964
				c0.02,0.495,0.04,0.99,0.06,1.485c4.082-0.565,8.085,0.066,12.151,0.433c-0.083-0.475-0.167-0.95-0.251-1.425
				c-4.665,1.748-10.6,12.826-3.662,15.557c6.822,2.686,6.998-4.129,2.828-7.693c-2.398-2.051-5.051-3.006-8.075-1.821
				c-3.063,1.2-4.338,6.006-2.926,8.796c1.065,2.105,2.25,3.308,4.344,4.365c1.288,0.65,13.564,4.31,7.123,6.132
				c-4.022,1.139-8.831,0.141-12.926-0.206c-0.047,0.498-0.094,0.996-0.142,1.494c3.536,0.108,21.392,0.997,19.606,6.865
				c-1.775,5.834-15.129-1.083-19.398,2.086c-3.984,2.958,2.875,8.738,4.889,10.133c6.663,4.613,15.184,7.092,23.052,8.619
				c1.895,0.368,12.792,1.933,10.556-2.657c-1.304-2.678-6.796-4.097-9.175-4.933c-4.723-1.66-9.729-2.277-14.606-1.042
				c-4.328,1.097-14.058,3.775-13.507,9.667c0.517,5.532,10.645,3.699,13.722,2.562c0.745-0.275,0.424-1.325-0.251-1.425
				c-9.731-1.434-7.804,13.577,0.033,14.873c0.617,0.102,0.925-0.578,0.749-1.069c-1.457-4.041-5.163-7.093-9.709-6.464
				c-2.839,0.393-8.373,2.334-6.712,6.439c1.526,3.773,5.346,4.282,8.944,4.628c1.015,0.098,2.026,0.208,3.037,0.338
				c3.405,0.438,5.502,3.128,0.238,4.752c-3.473,1.071-7.405,0.427-10.999,0.915c-0.856,0.116-0.767,1.395,0.06,1.485
				c4.394,0.481,8.806,0.472,13.214,0.739c-0.181-0.393-0.362-0.785-0.543-1.178c-3.647,6.391,0.706,13.579-3.541,19.656
				c0.439,0.23,0.878,0.461,1.318,0.691c1.492-3.134-0.271-5.521-2.633-7.617c-0.845-0.749-5.042-4.189-6.473-3.511
				c-3.274,1.554-5.326,4.703-3.849,8.129c1.8,4.174,8.239,4.733,12.013,4.458c-0.151-0.472-0.302-0.943-0.452-1.416
				c-9.043,2.945-17.619,8.682-27.487,7.568c-9.749-1.1-15.951-11.417-9.84-19.583c5.871-7.846,19.306-9.067,24.301-0.109
				c2.667,4.784,3.089,10.686,3.508,16.03c0.978,12.474,0.838,25.025,1.355,37.521c0.216,5.23,0.505,10.483,1.31,15.661
				c0.368,2.365,0.812,5.535,2.755,7.19c0.737,0.628,1.591-0.606,0.86-1.229c-3.189-2.716-2.793--11.191-3.064,0.739z"/>
		</g>
	</g>
	<path fill="none" stroke="#FFFF00" d="M82.884,846.542c0,0-2.787-6.503-0.464-3.251C84.742,846.542,82.884,846.542,82.884,846.542z
		"/>
	<path fill="none" stroke="#FFFF00" d="M82.884,931.083c1.858,0,5.574,0.464,3.716,0.93
		C84.742,932.478,82.884,931.083,82.884,931.083z"/>
	<path fill="none" stroke="#FFFF00" d="M82.42,939.91l2.322-0.465c0,0,0.929,1.858-0.464,1.858S82.42,939.91,82.42,939.91z"/>
</g>
</svg>
      </div>
    );
  }
}

export default LineThesis;
