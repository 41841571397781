import React, { Component }  from 'react';
import LineCV from './LineCV';


class CV extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="cv">
        <LineCV />
        <h2>LEBENSLAUF</h2>
        <table>
            <tbody>
                <tr>
                    <td className="table-left">seit 02 | 2019</td>
                    <td className="table-right">Freiberufliche Selbstständigkeit Web- und App-Entwicklung</td>
                </tr>
                <tr>
                    <td className="table-left">10 | 2014
                        <br/>- 10 | 2019</td>
                    <td className="table-right">
                        Studium Medieninformatik (B. Sc.) Hochschule der Medien Stuttgart
                        Abschlussnote: 1,3
                    </td>
                </tr>
                <tr>
                    <td className="table-left">04 | 2017
                        <br/>- 11 | 2018</td>
                    <td className="table-right">Werkstudententätigkeit im Bereich App-Entwicklung Acando GmbH Stuttgart</td>
                </tr>
                <tr>
                    <td className="table-left">09 | 2016 
                        <br/>- 02 | 2017</td>
                    <td className="table-right">Praktikum im Bereich App-Entwicklung BRICKMAKERS GmbH Koblenz</td>
                </tr>
                <tr>
                    <td className="table-left">08 | 2011 
                        <br/>- 06 | 2014</td>
                    <td className="table-right">
                        Beruflches Gymnasium Gestaltungs- und Medientechnik Julius-Wegeler-Schule Koblenz
                        Abschlussnote: 1,1
                    </td>
                </tr>
                
            </tbody>
        </table>
      </div>
    );
  }
}

export default CV;
