import React from 'react';
import './App.css';
import Header from './components/Header';
import CV from './components/CV';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Impressum from './components/Impressum';
import Thesis from './components/Thesis';
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
      <Header />
      <CV />
      <Skills />
      <Projects />
      <Thesis />
      <Contact />
      <Impressum />
    </div>
  );
}

export default App;
