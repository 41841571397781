import React, { Component }  from 'react';
import LineMoosic from './LineMoosic';
import imageMoosic1 from './../images/moosic/moosic1.png';
import imageMoosic2 from './../images/moosic/moosic2.png';
import imageMoosic3 from './../images/moosic/moosic3.png';
import imageMoosic4 from './../images/moosic/moosic4.png';
import imageMoosic5 from './../images/moosic/moosic5.png';
import imageMoosic6 from './../images/moosic/moosic6.png';

import { smoothScroll } from '../helperFunctions/smoothScrolling';


class Moosic extends Component {

  componentDidMount() {
    smoothScroll('.moosic', 2000);
  }

  render() {
    return (
      <div className="moosic">
       <LineMoosic /> 
        <h2>moosic</h2>
        <p> 
            2017
            <br/><br/>
            Eine mobile Web Application, die anhand von angegebenen ‘mood’-Tags passende Spotify-Playlisten vorschlägt. Hierbei wurde mit React.js und der Spotify-API gearbeitet. Das Projekt wurde im Rahmen der Vorlesung <a href="https://www.hdm-stuttgart.de/vorlesung_detail?vorlid=5212603">"Moblie Web Application"</a> entwickelt. 
            <br/><br/>
            Technologien: React.js, SCSS
            <br/><br/>
            Team: Aurelia Bachmann, Josefine Hartung, Sonja Nürenberg, Antonia Verdier
        </p>
        <div className="moosic-app-images">
          <img id="image-moosic2" src={imageMoosic2} alt="Screenshot Project moosic" />
          <img id="image-moosic3" src={imageMoosic3} alt="Screenshot Project moosic" />
          <img id="image-moosic4" src={imageMoosic4} alt="Screenshot Project moosic" />
        </div>
        <div className="moosic-web-images">
          <img id="image-moosic1" src={imageMoosic1} alt="Screenshot Project moosic" />
          <img id="image-moosic5" src={imageMoosic5} alt="Screenshot Project moosic" />
          <img id="image-moosic6" src={imageMoosic6} alt="Screenshot Project moosic" />
        </div>
      </div>
    );
  }
}

export default Moosic;
