import React, { Component }  from 'react';

class Skills extends Component {

  render() {
    return (
      <div className="skills">
        <h2>FÄHIGKEITEN</h2>
        <h4 className="skill1 small">Clojure</h4>
        <h4 className="skill2 small">XML</h4>
        <h4 className="skill3 medium">Java</h4>
        <h4 className="skill4 medium">Swift</h4>
        <h4 className="skill5 medium">SQL</h4>
        <h4 className="skill6 medium">UX</h4>
        <h4 className="skill7 medium">Usability</h4>
        <h4 className="skill8 medium">Englisch<br /><span className="small">(fließend in Wort und Schrift)</span></h4>
        <h4 className="skill9 medium">Französisch<br /><span className="small">(Grundkenntnisse)</span></h4>
        <h4 className="skill10 medium">TDD</h4>
        <h4 className="skill11 big">React.js</h4>
        <h4 className="skill12 big">HTML</h4>
        <h4 className="skill13 big">CSS/SCSS</h4>
        <h4 className="skill14 big">JavaScript</h4>
        <h4 className="skill15 big">SCRUM</h4>
        <h4 className="skill16 big">Phyton</h4>
      </div>
    );
  }
}

export default Skills;
